//import React from 'react';
import './Background.css'; // Импорт стилей для компонента
import React, { useEffect, useRef } from 'react';

function Background() {

    const interBubbleRef = useRef(null); // Используем ref для получения элемента

    useEffect(() => {
        const interBubble = interBubbleRef.current; // Получаем элемент через ref
        let curX = 0;
        let curY = 0;
        let tgX = 0;
        let tgY = 0;
    
        const move = () => {
          curX += (tgX - curX) / 20;
          curY += (tgY - curY) / 20;
          if (interBubble) {
            interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
          }
          requestAnimationFrame(move);
        };
    
        const handleMouseMove = (event) => {
          tgX = event.clientX;
          tgY = event.clientY;
        };
    
        window.addEventListener('mousemove', handleMouseMove); // Добавляем слушатель движения мыши
        move(); // Запускаем анимацию
    
        // Чистим обработчики при размонтировании компонента
        return () => {
          window.removeEventListener('mousemove', handleMouseMove);
        };
      }, []);


    return (
        <div class="gradient-bg">
        <svg 
             viewBox="0 0 100vw 100vw"
             xmlns='http://www.w3.org/2000/svg'
             class="noiseBg"
             >
          <filter id='noiseFilterBg'>
            <feTurbulence 
                          type='fractalNoise'
                          baseFrequency='0.6'
                          stitchTiles='stitch' />
          </filter>
      
          <rect
                width='100%'
                height='100%'
                preserveAspectRatio="xMidYMid meet"
                filter='url(#noiseFilterBg)' />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="svgBlur">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
        <div class="gradients-container">
          <div class="g1"></div>
          <div class="g2"></div>
          <div class="g3"></div>
          <div class="g4"></div>
          <div class="g5"></div>
          <div class="interactive" ref={interBubbleRef}></div>
        </div>
      </div>
    );
  }
  
  export default Background;

