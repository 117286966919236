import React, { useState } from 'react';
import './App.css';
import './gallery.css';
import './fade.css';
import './Background.css';
import { ReactComponent as GyozaSVG } from './gyoza5.svg';
import Background from './Background'; // Импортируйте ваш компонент

import img1 from './image1.png';
import img2 from './image2.png';
import img3 from './image3.png';
import img4 from './image4.png';
import img5 from './image5.png';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('home'); // Управляет текущей страницей

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleNavigation = (page) => {
    setCurrentPage(page);
    setIsOpen(false);
  };


  document.addEventListener('DOMContentLoaded', () => {
    const interBubble = document.querySelector('.interactive');
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const move = () => {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(move);
    };

    window.addEventListener('mousemove', (event) => {
        tgX = event.clientX;
        tgY = event.clientY;
    });

    move();
});


  return (
    <div className="App">
    <Background />
      <nav className="navbar">
        {!isOpen && (
          <div className={`burger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        )}

        <div className={`close-icon ${isOpen ? 'show' : ''}`} onClick={closeMenu}>
          &times;
        </div>

        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
         {/*  <li><a href="#" onClick={() => handleNavigation('home')}>Main</a></li>*/}
          <li><a href="https://dexscreener.com/ethereum/0x4e51a6b3cc6d5ae69a0d44db9de846aeb5a582dd" target="_blank" rel="noopener noreferrer" onClick={() => setIsOpen(false)}>Chart</a></li>
          <li><a href="https://gyoza.gitbook.io/gyoza-docs" target="_blank" rel="noopener noreferrer" onClick={() => setIsOpen(false)}>Guide/About</a></li>
          <li><a href="https://twitter.com/Gyoza_erc20/" target="_blank" rel="noopener noreferrer" onClick={() => setIsOpen(false)}>Twitter</a></li>
          <li><a href="https://t.me/gyozatoken" target="_blank" rel="noopener noreferrer" onClick={() => setIsOpen(false)}>Telegram</a></li>

        {/*  <li><a href="#" onClick={() => handleNavigation('gallery')}>Examples</a></li>*/}
        </ul>
      </nav>



      <div className="content">
        <div className={`${currentPage === 'home' ? 'fade-in' : 'fade-out'}`}>
          {currentPage === 'home' && (
            <>
              <GyozaSVG className="glowing-svg" />
              <h1 className="gyoza-text">GYOZA</h1>
              <a href="https://t.me/GyozaWardenBot" target="_blank" rel="noopener noreferrer">
  <h1 className="warden-text">JOIN</h1>
</a>

            </>
          )}
        </div>

        <div className={` ${currentPage === 'gallery' ? 'fade-in' : 'fade-out'}`}>
          {currentPage === 'gallery' && (
            <div className="gallery-container">
              <img src={img1} alt="Example 1" />
              <img src={img2} alt="Example 2" />
              <img src={img3} alt="Example 3" />
              <img src={img4} alt="Example 4" />
              <img src={img5} alt="Example 5" />
            </div>
          )}
        </div>
      </div>
  

    </div>
  );
}

export default App;
